<template>
    <div>
        <h1>User Profile</h1>
    </div>
</template>

<script>
export default {

}
</script>

<style scoped>
div{
    width: 200px;
    height: 200px;
    background-color: red;
}

</style>